var init_connect_landing = function() {
  if ($('.c-connect').length > 0) {
      var connectTL = gsap.fromTo($('.c-connect'), .5, {alpha: 0, y: -50} , {alpha: 1, y: 0})

      var connectScene = new ScrollMagic.Scene({
    	 triggerElement: '.c-connect',
    	 triggerHook: .7,
    	 reverse: false,
     })
    	 .setTween(connectTL)
    	 .addTo(controller);

  }
}
window.init_funs.push(init_connect_landing)
