var init_skyline = function() {
  /* City decoration animation
		as you scroll, change postion of layers */
		if ($('.js-city-dec').length > 0) {
			var layersLGTL = new TimelineMax()
				.fromTo($('.c-city-dec--lg .c-city-dec__center'), .3, {y: 90} , {y: 0}, '-=.2')
        .fromTo($('.c-city-dec--lg .c-city-dec__back'), .4, {y: 170} , {y: 0}, '-=.3')


				var layersLGScene = new ScrollMagic.Scene({
				 triggerElement: '.c-city-dec--lg',
				 triggerHook: 0.5,
         offset: -210,
				 duration: '21%',
			 })
			 // .addIndicators({
				// 	name: 'scene',
				// 	colorTrigger: 'blue',
				// 	indent: 200,
				// 	colorStart: 'green',
				// 	colorEnd: 'red'
				// }) // this requires plugin
				 .setTween(layersLGTL)
				 .addTo(controller);

    }
}
window.init_funs.push(init_skyline)
