var init_job_landing = function() {
  if ($('.c-jobs').length > 0) {

    var jobTL = new TimelineMax()
      .fromTo($('.c-jobs h2, .c-jobs p'), 1, {alpha: 0, y: -10} , {alpha: 1, y: 0})
    	.fromTo($('.c-jobs .btn'), .3, {alpha: 0, y: -10} , {alpha: 1, y: 0})

    	var jobScene = new ScrollMagic.Scene({
    	 triggerElement: '.c-jobs',
    	 triggerHook: 1,
    	 reverse: false,
       offset: 300,
     })
    	 .setTween(jobTL)
    	 .addTo(controller);

  }
}
window.init_funs.push(init_job_landing)
