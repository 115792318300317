var init_hero_landing = function() {
  if ($('.c-hero').length > 0) {
    var heroTL = new TimelineMax()
      .fromTo($('.c-hero__dec-l'), 1, {alpha: 0, x: -500} , {alpha: 1, x: 0})
      .fromTo($('.c-hero__dec-r'), 1, {alpha: 0, x: 500} , {alpha: 1, x:0}, '-=1')
      .fromTo($('.c-hero h1, .c-hero p'), .3, {alpha: 0, y: -20} , {alpha:1, y:0})
      .fromTo($('.c-hero .c-hero__cta'), .3, {alpha: 0, y: -20} , {alpha:1, y:0})
      .fromTo($('.c-hero .c-hero__pointer'), .3, {alpha: 0, y: -30} , {alpha:1, y:0})
  }

  /* Pointer animation */
  var pointerTL = gsap.to($('button.c-hero__pointer'), .5, {y: 10 , paused:true});
  $('button.c-hero__pointer').mouseenter(function(){
    pointerTL.play()
  });

  $('button.c-hero__pointer').mouseleave(function(){
    var pointerTLTime = pointerTL.time();
    pointerTL.reverse(pointerTLTime);
  });

  /* HERO BUTTONS BEHAVIOUR */

  var heroHeight;

  // Small screen buttons
  $('button.c-hero__pointer--SM').click(function(){
    heroHeight = $('.c-hero').height();
    gsap.to(window, .4, {scrollTo:{y:heroHeight, offsetY: -60}});
  });
  $('.c-hero__cta--SM button.btn').click(function(){
    heroHeight = $('.c-hero').height();
    gsap.to(window, .4, {scrollTo:{y:heroHeight, offsetY: -60}});
  });

  // Large screen buttons
  $('button.c-hero__pointer--MD').click(function(){
    heroHeight = $('.c-hero').height();
    gsap.to(window, .4, {scrollTo:{y:heroHeight, offsetY: -150}});
  });
  $('.c-hero__cta--MD button.btn').click(function(){
    heroHeight = $('.c-hero').height();
    gsap.to(window, .4, {scrollTo:{y:heroHeight, offsetY: -150}});
  });



}
window.init_funs.push(init_hero_landing)
