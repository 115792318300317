var init_description_landing = function() {
  if ($('.c-description').length > 0) {

    var descriptionTL = gsap.fromTo($('.c-description__container'), 1, {alpha: 0, y: -50} , {alpha: 1, y: 0})

  	var descriptionScene = new ScrollMagic.Scene({
  	 triggerElement: '.c-description',
  	 triggerHook: 1,
  	 reverse: false,
     offset: 300,
   })
  	 .setTween(descriptionTL)
  	 .addTo(controller);

  }
}
window.init_funs.push(init_description_landing)
