var init_testimonial_landing = function() {
  if ($('.c-testimonial').length > 0) {

    $('.c-testimonial__img').each(function(index, el){

      var testimonialTL = new TimelineMax()
      .fromTo($(el), .5, {alpha: 0, x: 50} , {alpha: 1, x: 0})
      .fromTo($(el).siblings(), .5, {alpha: 0, y: -50} , {alpha: 1, y: 0}, '-=.3')

      var testimonialScene = new ScrollMagic.Scene({
    	 triggerElement: el,
    	 triggerHook: .7,
    	 reverse: false,
     })
    	 .setTween(testimonialTL)
    	 .addTo(controller);


    });
  }
}
window.init_funs.push(init_testimonial_landing)
