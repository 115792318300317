var init_blog_landing = function() {
  if ($('.c-blog').length > 0) {

      var blogTL = new TimelineMax()
      .fromTo($('.c-blog h2'), .5, {alpha: 0, y: -50} , {alpha: 1, y: 0})
      .fromTo($('.c-blog .owl-carousel'), .3, {alpha: 0, x: 50} , {alpha: 1, x: 0})
      .fromTo($('.c-blog .btn'), .3, {alpha: 0, y: -50} , {alpha: 1, y: 0})
      .fromTo($('.c-blog button'), .3, {alpha: 0} , {alpha: 1})

      var blogScene = new ScrollMagic.Scene({
    	 triggerElement: '.c-blog',
    	 triggerHook: .7,
    	 reverse: false,
     })
    	 .setTween(blogTL)
    	 .addTo(controller);

  }
}
window.init_funs.push(init_blog_landing)
