var init_about_landing = function() {
  if ($('.c-team').length > 0) {
      var teamTL = gsap.fromTo($('.c-team__row'), .5, {alpha: 0, y: -50} , {alpha: 1, y: 0})

      var teamScene = new ScrollMagic.Scene({
    	 triggerElement: '.c-team',
    	 triggerHook: .7,
    	 reverse: false,
     })
    	 .setTween(teamTL)
    	 .addTo(controller);

  }
}
window.init_funs.push(init_about_landing)
