var init_meet_team = function() {

  /* TEAM BUTTON EXPAND */
  $(".js-meet-team").click(function() {
       $(this).closest('.c-meet-team__item').toggleClass('c-meet-team__item--open');
  });

  /* SPECIAL CASE FOR 3+ social icons */
  $(".js-meet-team-footer").each(function(){
    if( $(this).children('.c-meet-team__social').children('a').length > 3 ) {
      $(this).addClass('c-meet-team--special');
      $(this).parent().attr('style', 'height: auto; min-height: 190px');
    }
  });
}
window.init_funs.push(init_meet_team)
