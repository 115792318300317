var init_clients_landing = function() {
  if ($('.c-clients').length > 0) {

    var clientsTL = new TimelineMax()
    .fromTo($('.c-clients h2'), 1, {alpha: 0, y: -50} , {alpha: 1, y: 0})
    .fromTo($('.c-clients__img'), 1, {alpha: 0, scale: 1.2} , {alpha: 1, scale: 1}, '-=.5')

  	var clientsScene = new ScrollMagic.Scene({
  	 triggerElement: '.c-clients',
  	 triggerHook: 1,
  	 reverse: false,
     offset: 300,
   })
  	 .setTween(clientsTL)
  	 .addTo(controller);

  }
}
window.init_funs.push(init_clients_landing)
