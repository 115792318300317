var init_my_navbar = function() {
  /* if header has color, turn navbar to match */
  if($('header').hasClass('js-convert-nav')) {
    $('.navbar').addClass('t-navbar');
  }



  // navbar mobile expanded style
	function addNavCls() {
		$('.navbar-logo, .navbar-toggler-icon').addClass('Dark');
	}

	function remNavCls() {
		$('.navbar-logo, .navbar-toggler-icon').removeClass('Dark');
	}

  /* check theme of navbar */
  var navbar_color = '#FFFFFF';
  if ($(".navbar--SM").hasClass('t-navbar')) {
    navbar_color = $('.t-navbar').css('background-color');
  }

  $( ".navbar-btn" ).click(function() {

		$(".navbar--SM").toggleClass("open");
		if ($(".navbar--SM").hasClass('open')) {
			var navbarTl = gsap.timeline()
				.set($('.navbar--SM'), {background: '#000'})
				.set($('.js-navbarContent .nav-item'), {autoAlpha: 0})
				.set($('.js-navbarContent .navbar-social'), {autoAlpha: 0})
				.call(addNavCls)
				.fromTo($('.js-navbarContent'), .2, {height: 0, display: 'block', overflow: 'hidden'}, {height: '100vh'})
				.to($('.navbar--SM'), 0.1, {position: 'fixed'})
				.to($('.js-navbarContent .nav-item'), .15, {autoAlpha: 1, stagger: 0.1})
				.to($('.js-navbarContent .navbar-social'), .15, {autoAlpha: 1});

		} else {
			var navbarReverseTl = gsap.timeline()
				.fromTo($('.js-navbarContent .navbar-social'), .15, {autoAlpha: 1}, {autoAlpha: 0})
				.fromTo($('.js-navbarContent .nav-item'), .15, {autoAlpha: 1}, {autoAlpha: 0, stagger: -0.1})
				.to($('.navbar--SM'), 0.1, {position: 'static'})
				.fromTo($('.js-navbarContent'), .2, {height: '100vh', display: 'block'}, {height: '0', display: 'none'})
				.to($('.navbar--SM'), 0.1, {background: navbar_color})
				.call(remNavCls);
		}
  });
  // // navbar dropdown on hover
  // $(".nav-item.dropdown").mouseover(function() {
  //   $(this).addClass('nav-item--expand-hover');
  // });
  // $(".nav-item.dropdown").mouseout(function() {
  //   $(this).removeClass('nav-item--expand-hover');
  // });

  // used for contact page
  if ($(".js-convert-background").length > 0) {
      $("main[role='main']").addClass("t-grey")
  }
  // contact thank-you
  if ($(".js-convert-background--2").length > 0) {
      $("main[role='main']").addClass("t-color")
  }

}
window.init_funs.push(init_my_navbar)
