var init_forms = function() {
  /* checkbox toggle */
  $('input[type="checkbox"]').click(function(){
    $(this).toggleClass('selected');
  });

  /* select inputs */
  $('.js-select').select2({
    width: '100%',
    minimumResultsForSearch: -1,
  });

  /* File input */
    $(".form-file #cv").change(function (e){
       var fileName =  e.target.files[0].name;

       if ($(this).valid() == true) {
         /* If File name is too long, trim it and add ellipsis */
         if (fileName.length >= 27) {
           var trimmedFileName = fileName.substring(0, 27) + '...';
           $(this).siblings(".form-file__mask").html(trimmedFileName);
         } else {
          $(this).siblings(".form-file__mask").html(fileName);
         }

       } else {
         $(this).siblings(".form-file__mask").html('CHOOSE FILE');
       }
    });
}
window.init_funs.push(init_forms)
