/* ========== Init ScrollMagic ========== */
var controller= new ScrollMagic.Controller();
// create container for initialisation functions
// init functions need to be re-run after every CMS change to re-initialise
// newly injected front-end elements
window.init_funs = []

var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


var init_browser_check = function() {
  if(is_safari) {
    $('.c-hero__content').addClass('c-hero__content--webkit');
  }
}
window.init_funs.push(init_browser_check)


var init_job_search = function() {
    /* Job listing filters */
    var form = $("#jobs-form")
    var base_url = form.attr("data-baseurl")
    form.find("select").change(function(e) {
        console.log("Changed")
        var url = base_url+"?"

        if ($("#job-location").val()) {
            url += "location="+$("#job-location").val()+"&"
        }
        if ($("#job-role").val()) {
            url += "role="+$("#job-role").val()+"&"
        }
        if ($("#job-type").val()) {
            url += "type="+$("#job-type").val()+"&"
        }
        window.location.replace(url+"#"+$(document).scrollTop());
    })
}
window.init_funs.push(init_job_search)


/* OWL CAROUSEL ============================================================ */
var init_owl_carousel = function() {
  var owl = $('.owl-carousel');
  owl.owlCarousel({
    autoWidth: true,
    dots: false,
    responsive:{
                 0:{
                     loop: true,
                     nav: false,
                 },
                 992:{
                     loop: false,
                     nav:true,

                 }
               }
  });
  // Go to the next item
  $('.c-blog__controls .next').click(function() {
       owl.trigger('next.owl.carousel');
  });
  // Go to the previous item
  $('.c-blog__controls .prev').click(function() {
      // With optional speed parameter
      // Parameters has to be in square bracket '[]'
      owl.trigger('prev.owl.carousel');
  });
}
window.init_funs.push(init_owl_carousel)


var init_blog_redirs = function() {
    $("select.js-blog-category-picker").change(function(e) {
        var el = $(this)
        var val = el.val()
        if (val) {
            window.location.href = val
        }
    })
}
window.init_funs.push(init_blog_redirs)

var init_blog_load_more = function() {

    var current_page = 1

    $(".c-blog-list__cta button").click(function(e) {
        e.preventDefault()
        var el = $(this)
        var STEP = 3 // fixed in cnk_cg1_cms_plugins.views
        var offset = parseInt(el.attr("data-pagesize"))/STEP

        el.attr("disabled", true)
        el.text("Loading...")
        var BASE_URL = el.attr("data-href")
        var url = BASE_URL+"?page="+(current_page+offset)

        var category = el.attr("data-category")
        if (category.length > 0) {
            url += "&category="+category
        }

        $.ajax({
          url: url,
        }).done(function(data) {
            if (data.has_next == true) {
                current_page += 1
                el.text("Load more")
                el.removeAttr("disabled")
            } else {
                el.fadeOut(100)
            }
            var newel = $(data.content)
            var extra_styles = newel.find("script.extra_style");
            $(extra_styles).each(function(index, el) {
                $("head").append($(this).html())
            })
            newel.find("script.extra_style").detach();
            $(".c-blog-list__content").append(newel);
        });
    })


}
window.init_funs.push(init_blog_load_more)


var init_contact_form = function() {
    var selector = "form#contact"

    var _init_contact = function(selector) {

        var button = $(selector).find("button")
        var _init_form = function() {
            $(selector).validate({
              errorPlacement: function(error, element) {
                if (element.attr("name") == "policy" ) {
                  error.insertAfter('label[for="policy"]');
                } else {
                  error.insertAfter(element);
                }
              }
            })
            $(selector).submit(function(e) {
                e.preventDefault()
                if ($(this).valid()) {
                    // if (! $("#policy").is(':checked')) {
                    //     alert("Please make sure you agree with our Privacy Policy")
                    //     return
                    // }

                    var form_url = $(selector).attr('action')
                    e.preventDefault();
                    $.ajax({
                        type: "POST",
                        url: form_url,
                        data: $(selector).serialize(), // serializes the form's elements.
                        success: function(data) {
                            console.log("Success")
                            //console.log(data)
                            // redirect to thank-you
                            if (data.status == "OK") {
                                window.location.href = $(selector).attr("data-thankyou")
                            } else {
                                alert(data.reason)
                            }

                        },
                        error: function(data) {
                            console.log("Error")
                            console.log(data)
                            alert("There was an error when submitting the form "+data)
                        }
                    });
                } else {
                    // alert("Form is not valid")
                }
            })
        }

        button.attr("disabled")
        // fill-in token
        $.ajax({
          dataType: "json",
          url: "/_api/gtk/",
          success: function(data) {
              $("form.contact-form").append(
                  '<input type="hidden" name="csrfmiddlewaretoken" value="'+data+'">')
              _init_form()
              button.removeAttr("disabled")
          }
        });
    }

    if ($(selector).length > 0) {
        _init_contact(selector)
    }
}
window.init_funs.push(init_contact_form)


var init_hs_contact_form = function() {
    var selector = "form#hs_contact"

    var _init_hs_contact = function(selector) {

        var button = $(selector).find("button")
        var _init_form = function() {
            $(selector).validate({
              errorPlacement: function(error, element) {
                if (element.attr("name") == "policy" ) {
                  error.insertAfter('label[for="policy"]');
                } else {
                  error.insertAfter(element);
                }
              }
            })
            $(selector).submit(function(e) {
                button.attr("disabled")
                var cookie = Cookies.get("hubspotutk");

                e.preventDefault()
                if ($(this).valid()) {

                    // if (! $("#policy").is(':checked')) {
                    //     alert("Please make sure you agree with our Privacy Policy")
                    //     return
                    // }

                    e.preventDefault();

                    var formData = {
                      "submittedAt": $("#id_timestamp").val(),
                      "fields": [
                        {
                          "name": "firstname",
                          "value": $("#first_name").val()
                        },
                        {
                          "name": "lastname",
                          "value": $("#last_name").val()
                        },
                        {
                          "name": "email",
                          "value": $("#email").val()
                        },
                        {
                          "name": "jobtitle",
                          "value": $("#job").val()
                        },
                        {
                          "name": "company",
                          "value": $("#company").val()
                        },
                        {
                          "name": "website",
                          "value": $("#website").val()
                        },
                        {
                          "name": "message",
                          "value": $("#message").val()
                        },

                      ],
                      "context": {
                        "hutk": cookie,
                        "pageUri": $(location).attr("href"),
                        "pageName": "Framewurk",
                      },
                    }

                    $.ajax({
                      type: "POST",
                      url: "https://api.hsforms.com/submissions/v3/integration/submit/19511847/2f3bdce0-0633-4917-927c-6d82b280f51c",
                      data: JSON.stringify(formData),
                      dataType: "json",
                      contentType : "application/json",
                      success: function(data) {
                          window.location.href = $(selector).attr("data-thankyou")
                      },
                      error: function(xhr){
                          console.log(xhr)
                          button.removeAttr("disabled")
                          if (xhr.status == 400) {
                            var data=xhr.responseText;
                            var jsonResponse = JSON.parse(data);
                            var specificError = jsonResponse['errors'][0].message;

                            alert("There was an error when submitting the form:\n"+specificError);

                          } else {
                              alert("Something went wrong. Please try again later or contact us if the error persists.");
                          }
                      }
                    });
                } else {
                    button.removeAttr("disabled")
                }
            })
        }

        _init_form();
    }

    if ($(selector).length > 0) {
        _init_hs_contact(selector)
    }
}
window.init_funs.push(init_hs_contact_form)



var init_job_application = function() {
    var selector = "form#form-apply"
    var _init_jobapplication = function(selector) {

        var button = $(selector).find("button")
        button.attr("disabled")
        var _init_form = function() {
            $(selector).validate({
              rules: {
                  cv: {
                    required: true,
                    extension: "pdf|doc"
                  }
              },
              messages: {
                cv: {
                  extension: "Please upload pdf or doc"
                }
              }
            })
            $(selector).submit(function(e) {
                e.preventDefault()
                if ($(this).valid()) {
                    var form_url = $(selector).attr('action')
                    e.preventDefault();

                    var data = new FormData($(selector)[0]);

                    console.log(data)

                    $.ajax({
                        type: "POST",
                        url: form_url,
                        enctype: 'multipart/form-data',
                        processData: false,  // Important!
                        cache: false,
                        contentType: false,
                        data: data,
                        success: function(data) {
                            console.log("Success")
                            //console.log(data)
                            // redirect to thank-you
                            // window.location.href = $(selector).attr("data-thankyou")
                            if (data.status == "OK") {
                                $(selector).hide()
                                $(".c-form-apply-message").fadeIn()
                            } else {
                                alert(data.reason)
                            }
                        },
                        error: function(data) {
                            console.log("Error")
                            console.log(data)
                            alert("There was an error when submitting the form "+data)
                        }
                    });
                } else {
                    // alert("Form is not valid")
                }
            })
        }

        $.ajax({
          dataType: "json",
          url: "/_api/gtk/",
          success: function(data) {
              $(selector).append(
                  '<input type="hidden" name="csrfmiddlewaretoken" value="'+data+'">')
              _init_form()
              button.removeAttr("disabled")
          }
        });
    }
    if ($(selector).length > 0) {
        _init_jobapplication(selector)
    }
}
window.init_funs.push(init_job_application)

var init_job_link = function() {
    var job_link = $("a.nav-link[href='/job-search/']")
    job_link.attr("href", "/jobs/")
}
window.init_funs.push(init_job_link)


/* BUTTONS THAT SCROLL TO FOOTER */
var init_scroll_bottom = function() {
  $('.js-scroll-bottom').click(function(){
    gsap.to(window, .4, {scrollTo: {y:$('.c-footer__text')}});
  });
}
window.init_funs.push(init_scroll_bottom)


var init_mailchimp = function() {
    $("#mc_embed_signup form").validate()
}
window.init_funs.push(init_mailchimp)
